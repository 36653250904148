import { useEffect, useState } from 'react'

/**
 *
 * @param {Object} options
 * @param {Array<*>} [options.conditions] Conditions to test
 */
const useLoadingScreen = ({ conditions = [] }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (Array.isArray(conditions)) {
      setLoading(conditions.filter(val => val).length !== conditions.length)
    } else {
      setLoading(false)
    }
  }, [conditions])

  return { loading }
}

export default useLoadingScreen
