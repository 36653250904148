import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import Content from 'components/content-full-width'

const LoadingScreen = ({ loading, message = 'Loading...', style = {}, ...props }) => {
  return (
    <Content style={{ display: loading ? 'block' : 'none', ...style }} {...props}>
      <LoadingScreenContainer>
        <LoadingCircle />
        <LoadingText>{message}</LoadingText>
      </LoadingScreenContainer>
    </Content>
  )
}

LoadingScreen.propTypes = {
  loading: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  style: PropTypes.object,
}

const LoadingScreenContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`

const spinAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const LoadingCircle = styled.div`
  animation: ${spinAnimation} 0.67s infinite linear;
  border: 3px solid ${props => props.theme.color.n20};
  border-radius: 100%;
  border-top-color: ${props => props.theme.color.g400};
  box-sizing: border-box;
  height: 24px;
  width: 24px;
`

const LoadingText = styled.p`
  color: ${props => props.theme.color.n500};
  margin: 0 0 0 16px;
`

export default LoadingScreen
